import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ContentPane from '../../components/contentPane';


const SuccessPage = () => (
  <Layout>
    <SEO title="Paypal Payment Success" />
    <ContentPane title="Payment Successful" anchor="paypal-success">
      <h1>Thank you!</h1>
      <p>
See you at the event!  Please go back to the
        {' '}
        <a href="/">CritAid site</a>
        {' '}
to view the latest details on the event.
      </p>
    </ContentPane>
  </Layout>
);

export default SuccessPage;
